import { HTMLProps } from 'react'
import clsx from 'clsx'
import styles from './Loading.module.scss'
import { useTranslation } from 'react-i18next'

type LoadingProps = HTMLProps<HTMLDivElement> & {
  showText?: boolean
  text?: string
}

export function Loading(props: LoadingProps) {
  const { text, showText = false, className, ...rest } = props

  const { t } = useTranslation()

  const message = text ?? t('common.loading')

  return (
    <div
      {...rest}
      className={clsx(styles.loading, className)}
      data-testid="loading"
    >
      <span>{showText ? message : null}</span>
    </div>
  )
}
