import { Loading } from './Loading'
import styles from './LoadingPage.module.scss'

export function LoadingPage() {
  return (
    <div className={styles.LoadingPageWrapper}>
      <Loading showText text="Loading..." />
    </div>
  )
}
